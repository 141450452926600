import './floating-action-bar.scss';
import { getOffset } from '../../utilities/js/helper';

class FloatingActionBar {
    constructor ($element, options) {
        const defaults = {
            scrolledClassName: 'is--scrolled',
            initAttr: 'data-fab',
            toggleAttr: 'toggle',
            linkAttr: 'link',
            eventScroller: window.eventScroller
        };

        this.settings = Object.assign({}, defaults, options);

        this.$floatingActionBar = $element;
        this.$floatingToggle = this.$floatingActionBar.querySelector('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');
        this.$floatingLinks = this.$floatingActionBar.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.linkAttr + '"]');

        window.setTimeout(() => {
            this.initialize();
        }, 500);
    }

    initialize () {
        if (this.$floatingLinks.length > 0) {
            this.initFloatingLinks();
        }
        this.setEvents();
    }

    initFloatingLinks () {
        for (let i = 0; i < this.$floatingLinks.length; i++) {
            const $floatingLink = this.$floatingLinks[i];

            if ($floatingLink.href.indexOf('#') !== -1) {
                this.initFloatingScroller($floatingLink);
            }
        }
    }

    initFloatingScroller ($trigger) {
        const targetId = $trigger.href.split('#')[1];
        const $target = document.getElementById(targetId);

        if ($target) {
            const position = getOffset($target);
            const topPosition = position.top;

            $trigger.addEventListener('click', (e) => {
                if (this.$floatingToggle) {
                    this.$floatingToggle.checked = false;
                }

                window.scrollTo({
                    top: topPosition,
                    behavior: 'smooth'
                });

                e.preventDefault();
            });
        }
    }

    setEvents () {
        const eventScroller = this.settings.eventScroller;
        let initialScrollPosition = 0;

        if (eventScroller) {
            initialScrollPosition = eventScroller.getScrollInfo().position;

            eventScroller.customFunctions.push(() => {
                const windowHeight = window.innerHeight;
                const scrollInfo = eventScroller.getScrollInfo();
                const scrollPosition = scrollInfo.position;

                if (scrollPosition > initialScrollPosition + windowHeight) {
                    initialScrollPosition = 0;
                    this.$floatingActionBar.classList.add(this.settings.scrolledClassName);
                } else {
                    this.$floatingActionBar.classList.remove(this.settings.scrolledClassName);
                }
            });
        }
    }
}

export { FloatingActionBar };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $floatingActionBar = $context.querySelectorAll('[data-fab="root"]');

        for (let i = 0; i < $floatingActionBar.length; i++) {
            const $$floatingActionBar = new FloatingActionBar($floatingActionBar[i]);
            $$floatingActionBar.initialize();
        }
    }
});
